import React from "react";
import { Container } from "components/library";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { PersonDetails, ProfileCard } from "components/pages/profile/profile-card";

const details: PersonDetails = {
  name: "common.people.Yoichi Kadota.name",
  designation: "common.people.Yoichi Kadota.bio",
  image: '',
  contact: {
    email: "yoichi.kadota@polymerize.io",
    phone: "(+81) 9096723800",
    website: "www.polymerize.io",
    linkedin: "https://www.linkedin.com/in/yoichi-kadota-48117019/",
  },
  address: [
    "Polymerize Pte Ltd",
    "UEN 202013550M",
    "11 Irving Place, #09-01,",
    "Singapore 369551",
  ],
};
const YoichiKadotaProfile = () => {
  const { t } = useTranslation();

  return (
    <main className="bg-gray-200">
      <Container className="min-h-screen py-12 flex flex-col gap-8">
        <ProfileCard
          name={t(details.name) as string}
          designation={t(details.designation) as string}
          contact={details.contact}
          image={details.image}
        />
      </Container>
    </main>
  );
};

export default YoichiKadotaProfile;

// FOR LANGUAGE TRANSLATION
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
